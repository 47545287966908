<template>
  <v-text-field v-model="searchTerm" :placeholder="placeholder" variant="solo-filled" flat hide-details="true"
    rounded="lg" density="compact" :loading="loading">
    <template #prepend-inner>
      <AppIcon class="text-disabled" name="hugeicons:search-01" />
    </template>
  </v-text-field>
</template>

<!-- <script>
export default {
  inheritAttrs: false,
};
</script> -->

<script setup>
import { debounce } from "lodash";

const prop = defineProps({
  loading: {
    default: false,
  },
  placeholder: {
    default: "Pesquisar...",
  },
});

const emit = defineEmits(["update:modelValue", "input"]);

const searchTerm = ref(null);

watch(searchTerm, (value) => {
  debouncedSearch(value);
});

const debouncedSearch = debounce((value) => {
  emit("update:modelValue", value);
  emit("input", value);
}, 50);
</script>